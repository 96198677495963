import { render, staticRenderFns } from "./AppDealerEdit.vue?vue&type=template&id=ddcb5644&scoped=true&"
import script from "./AppDealerEdit.vue?vue&type=script&lang=js&"
export * from "./AppDealerEdit.vue?vue&type=script&lang=js&"
import style0 from "./AppDealerEdit.vue?vue&type=style&index=0&id=ddcb5644&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddcb5644",
  null
  
)

export default component.exports